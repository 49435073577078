import React, { useEffect, useState } from "react";
import { Button, CircularProgress, IconButton, ListItemText, makeStyles, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Tooltip, Typography, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import {GlobalUseStyles} from "./GlobalUseStyles.js";
import clsx from 'clsx';
import { Add, CancelOutlined, CheckCircleOutline, Error, ErrorOutline, GetApp, MoreHoriz, PlayArrow } from "@material-ui/icons";
import CustomStatusIcon from "./CustomStatusIcon.js";
import { useHistory } from "react-router-dom";
import { getValidations, downloadFromS3, downloadFromS3Base64, getValidation, deleteValidation, executeValidation, CancelDataValidation } from "./RestService.js";
import store from "store/store";
import { useSnackbar } from "react-simple-snackbar";
import { successSnackbarOptions, errorSnackbarOptions, warningSnackbarOptions } from "./SnackbarOptions.js";
import { useMsal } from "@azure/msal-react";
import InfiniteScroll from "react-infinite-scroller";
import { ConvertedTimeString } from "./Common.js";
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import SuccessIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles((theme) => ({
    customTableCell: {
        padding: "0px 16px",
        //width: "20%"
      },
      tableContainer: {
        position: "absolute", 
        top: "98px", 
        bottom: 0, 
        left: 0, 
        right: 0, 
        width:"95%",
        margin: "28px",
        overflowY: "scroll"
    }
}))

 
async function handleDownload(instance, validationName) {
    await downloadFromS3Base64(instance, "Validation", validationName);
}

var savedMaterialRevisions = [];
export function getSavedMaterialRevisions()
{
    savedMaterialRevisions = savedMaterialRevisions.filter(function(matrev) {return (matrev != undefined);});
    savedMaterialRevisions = savedMaterialRevisions.filter(function(matrev){return matrev.trim() != '';});
    return savedMaterialRevisions;
}
    
export default function DataValidation(props) {
    const classes = useStyles();
    const globalClasses = GlobalUseStyles();
    const [showFooter, setShowFooter] = React.useState(false);
    const [validationsLoading, setValidationsLoading] = useState(false);
    const [validationsLoaded, setValidationsLoaded] = React.useState(0);
    const [endReached, setEndReached] = React.useState(false);
    const [valLen, setValLen] = useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [anchorElDataVal, setAnchorElDataVal] = React.useState(null);
    const [selectedDataValidation, setSelectedDataValidation] = useState(null);
    const [validationData, setValidationData] = useState([]);
    const [showValidationData, setShowValidationData] = useState([]);
    const [showEmpty, setShowEmpty] = useState(false);
    const [reRender, setReRender] = useState(false);
    const [validtaionsUpdatedTime, setValidtaionsUpdatedTime] = useState(null);
    const [openSuccessSnackbar, closeSuccessSnackbar] = useSnackbar(successSnackbarOptions());
    const [openErrorSnackbar, closeErrorSnackbar] = useSnackbar(errorSnackbarOptions());
    const [openWarningSnackbar, closeWarningSnackbar] = useSnackbar(warningSnackbarOptions());
    const { instance, accounts, inProgress } = useMsal();
    const history = useHistory();
    const [openModal, setOpenModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [elementsToUpdate, setElementsToUpdate] = useState([]);
 
const AlertModal = ({ open, onClose, message }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ErrorIcon style={{ marginRight: '8px' }} />
          <Typography variant="h6">Alert</Typography>
        </div>
      </DialogTitle>
      <DialogContent>
        <Typography>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

    const loading = async () => {
        if (validationsLoading == false)
        {
            setValidationsLoading(true);

            setValidationsLoaded(validationsLoaded+25);
            setReRender(!reRender);
            setValidationsLoading(false);

            if (validationsLoaded > validationData.length)
            {
                setEndReached(true);
            }
        }
    }
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDataValClose = (event) => {
        setAnchorElDataVal(null);
    };

    const handleNewValidation = (event) => {
        props.setSelectedDataValidation(null);
        history.push("/datavalidation/edit");
    };

    const CancelButton = async (clickedBatch) => {
        let answer = window.confirm("Are you sure you want to cancel the run?");
        if (answer == true)
        {
            const cancelBatchResult = await CancelDataValidation(instance, clickedBatch["validationID"], clickedBatch["materials"], clickedBatch["options"], accounts[0].username, clickedBatch["name"], clickedBatch["lastRun"], clickedBatch["dvalertstatus"] );
            if (cancelBatchResult["exception"])
            {
                openWarningSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{cancelBatchResult["exception"]}</p></div>,300000)
            }            
            else
            {
              openSuccessSnackbar(<div style={{whiteSpace: "nowrap"}}><SuccessIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Data validation execute stopped."}</p></div>,300000);
              clickedBatch["status"] = "Cancelled";
              handleCancelClick(clickedBatch);
              setReRender(!props.reRender);
            } 
        }
    };

    const handleCancelClick = (clickedBatch) => {
        let tmpBatchData = validationData;
        var batch = tmpBatchData.findIndex((obj) => {
            return obj["validationID"] == clickedBatch["validationID"];
        });
        tmpBatchData[batch]["status"] = clickedBatch["status"];
        setValidationData(tmpBatchData);
    };


    const handleValidationDeleteClick = (event) => {
        if (confirm("Are you sure you want to delete validation " + selectedDataValidation["name"] + "?"))
        {
            var delResult = null;
            var message = "";
            setAnchorElDataVal(null);
            const data = async () => {
                delResult = await deleteValidation(instance, selectedDataValidation["validationID"], accounts[0].username);
            }
            data().catch((error)=>{
                console.error(error);
                message = "Error: " + error.message;
            }).then(() => {
                if (message != "")
                {
                    openErrorSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorOutline style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{message}</p></div>,300000);
                } 
                else 
                {
                    let tmpValidationData = [...validationData];
                    var newValidationData = tmpValidationData.filter((obj) => {
                        return obj["validationID"] !== selectedDataValidation["validationID"];
                    });
                    setValidationData(newValidationData);
                    if (newValidationData.length == 0)
                    {
                        setShowEmpty(true);
                    }
                    setReRender(!reRender);
                    openSuccessSnackbar(<div style={{whiteSpace: "nowrap"}}><CheckCircleOutline style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Compare deleted successfully."}</p></div>,300000);
                }
            });
        }
    };
        
     const CancelProcessingDV = async (clickedBatch) => {
  try {
    const cancelBatchResult = await CancelDataValidation(
      instance,
      clickedBatch["validationID"],
      clickedBatch["materials"],
      clickedBatch["options"],
      accounts[0].username,
      clickedBatch["name"],
      clickedBatch["lastRun"],
      clickedBatch["dvalertstatus"]
    );

    if (cancelBatchResult["exception"]) {
      openWarningSnackbar(
        <div style={{ whiteSpace: "nowrap" }}>
          <ErrorIcon
            style={{
              display: "inline-block",
              verticalAlign: "middle",
              marginRight: "14px",
            }}
            className={globalClasses.whiteIcon}
          />
          <p
            style={{
              display: "inline-block",
              verticalAlign: "middle",
            }}
          >
            {cancelBatchResult["exception"]}
          </p>
        </div>
      );
    }
else
{
              console.log("cancelBatchResult : " + cancelBatchResult);
              clickedBatch["status"] = "Cancelled";
              clickedBatch["dvalertstatus"] = "true";
              handleCancelClick(clickedBatch);
              setReRender(!props.reRender);
}
  } catch (error) {
    console.error("Error canceling data validation:", error);
  }
};

const UpdateDVAlertStatus = async (clickedBatch) => {
  try {
    const cancelBatchResult = await CancelDataValidation(
      instance,
      clickedBatch["validationID"],
      clickedBatch["materials"],
      clickedBatch["options"],
      accounts[0].username,
      clickedBatch["name"],
      clickedBatch["lastRun"],
      "false"
    );

    if (cancelBatchResult["exception"]) {
      openWarningSnackbar(
        <div style={{ whiteSpace: "nowrap" }}>
          <ErrorIcon
            style={{
              display: "inline-block",
              verticalAlign: "middle",
              marginRight: "14px",
            }}
            className={globalClasses.whiteIcon}
          />
          <p
            style={{
              display: "inline-block",
              verticalAlign: "middle",
            }}
          >
            {cancelBatchResult["exception"]}
          </p>
        </div>
      );
    }
else
{
              console.log("cancelBatchResult : " + cancelBatchResult);
              clickedBatch["status"] = "Cancelled";
              handleCancelClick(clickedBatch);
              setReRender(!props.reRender);
}
  } catch (error) {
    console.error("Error canceling data validation:", error);
  }
};
    let seconds = 0;    
    let b_getValidations = true;
    let emptyRows = 0;
    
    useEffect(() => {    
        if (b_getValidations) {
            b_getValidations = false;
            const data = async () => {
                let foundValidation = [];
                const newData = await getValidations(instance);   
                
                if (Array.isArray(newData)) {
                    let cancelMessages = [];
                    let updates = [];

                    newData.forEach(element => {
                        if (element["validationType"] == "Structure")
                        {
                            let matrev = element["materials"][0];
                            if (savedMaterialRevisions === undefined || savedMaterialRevisions.length == 0)
                                savedMaterialRevisions.push(matrev);
                            else if (savedMaterialRevisions.includes(matrev) == false) {
                                savedMaterialRevisions.push(matrev);
                            }
                        }
                            try {
                                const createdTime = new Date(element["lastRun"]);
                                const currentTime = new Date();

                                if (isNaN(createdTime.getTime())) {
                                    console.error("Invalid created time:", element["lastRun"]);
                                    return; 
                                }

                                const createdTimeMillis = createdTime.getTime();
                                const currentTimeMillis = currentTime.getTime();

                                const timeDiff = currentTimeMillis - createdTimeMillis;
                                const timeDiffInHours = timeDiff / (1000 * 60 * 60); // Convert milliseconds to hours

                                if (element["status"] === "Processing" && timeDiffInHours > 24) {
                                    element["status"] = "Cancelled";
                                    element["dvalertstatus"] = "true";
                                    element["dvAlertStatus"] = "true";
                                    console.log("After update:", element);
                                  
                                    cancelMessages.push(
                                       `Validation ${element["name"]} has been cancelled due to processing time exceeding 24 hours.`
                                    );
                                    updates.push(element);
                                    CancelProcessingDV(element);
                                }

                            } catch (error) {
                                console.error("Error processing date for element:", element, error);
                            }
                        
                    });

                   
                   if (cancelMessages.length > 0) {
                   setModalMessage(cancelMessages.join('\n'));
                   setElementsToUpdate(updates); 
                   setOpenModal(true);
                  }
                 } 
              else
                   {
                    if ("exception" in newData)
                     {
                     throw {"message": newData["exception"], "status": 404};
                      }
                   }
                
                let allData = [];
                allData = allData.concat(newData);
                allData.sort((a, b) => new Date(a["lastRun"]).valueOf() - new Date(b["lastRun"]).valueOf()).reverse();
                emptyRows = rowsPerPage - Math.min(rowsPerPage, allData.length - page * rowsPerPage);
                setValLen(allData.length);
                let tmpValData = [...allData];
                tmpValData = tmpValData.slice(0, 25);
                setValidationData([...allData]);
                setShowValidationData([...tmpValData]);
            };      
            data().catch((error) => {
            setShowEmpty(true);
            if ("status" in error && error["status"] == 404)
            {
                openWarningSnackbar(<div style={{whiteSpace: "nowrap"}}><Error style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{error.message}</p></div>,300000);
            }
            else
            {
                openErrorSnackbar(<div style={{whiteSpace: "nowrap"}}><Error style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{error.message}</p></div>,300000);
            }});
      }  
    },[]);

    useEffect(() => {
        validationData.sort((a, b) => new Date(a["lastRun"]).valueOf() - new Date(b["lastRun"]).valueOf()).reverse();
        emptyRows = rowsPerPage - Math.min(rowsPerPage, validationData.length - page * rowsPerPage);
        setValLen(validationData.length);
        let tmpValData = [...validationData];
        tmpValData = tmpValData.slice(0, validationsLoaded);
        setShowValidationData([...tmpValData]);
    },[reRender]);

const handleUpdate = () => {
    elementsToUpdate.forEach(element => UpdateDVAlertStatus(element));
    setOpenModal(false); 
  };
    const updateIntervalValidations = async () => {
        let processingValidations = validationData.filter((b) => b["status"] == "Processing")
        let tmpValidationData = [...validationData];
        const validationUpdates = processingValidations.map((b) => {
            return getValidation(instance, b["validationID"]);
        });
        await Promise.all(validationUpdates).then(data => {
            data.forEach(element => {
                var validation = tmpValidationData.findIndex((obj) => {
                    return obj["validationID"] == element["validationID"];
                });
               
                if (tmpValidationData[validation] != element)
                {
                    tmpValidationData[validation] = element;
                }
            });
            setShowValidationData(tmpValidationData);
            setValidationData(tmpValidationData);
            var date = new Date();
            setValidtaionsUpdatedTime(date);
        }).catch((err) => {
            openErrorSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorOutline style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Error: " + err}</p></div>,300000);
        });
    }

    const HandleExecuteDV = (_selectedVal) => {
        if (confirm("Are you sure you want to run validation " + _selectedVal["name"] + "?"))
        {
            console.log("yes: ", _selectedVal);

            let message = "";
            let executeData = null;
            if (_selectedVal["validationType"].toLowerCase() == "km list")
            {
                _selectedVal["options"]["sublevel"] = 0;
            }
            const executeValidationCall = async () => {
                executeData = await executeValidation(instance, _selectedVal["validationID"], _selectedVal["materials"], _selectedVal["options"], accounts[0].username);
                if (executeData["exception"])
                {
                    message += executeData["exception"];
                }
            };

            executeValidationCall().catch((error) => { message += error.message + "\n" }).then(() => {
                console.log("EXECUTED: ", executeData);
                if (message != "") {
                openErrorSnackbar(<div style={{ whiteSpace: "nowrap" }}><Error style={{ display: "inline-block", verticalAlign: "middle", marginRight: "14px" }} className={globalClasses.whiteIcon} /><p style={{ display: "inline-block", verticalAlign: "middle" }}>{"Error: " + message}</p></div>);
                }
                else {
                openSuccessSnackbar(<div style={{ whiteSpace: "nowrap" }}><CheckCircleOutline style={{ display: "inline-block", verticalAlign: "middle", marginRight: "14px" }} className={globalClasses.whiteIcon} /><p style={{ display: "inline-block", verticalAlign: "middle" }}>{"Validation execute started successfully."}</p></div>);
                

                let tmpValData = [...validationData];
                let tmpShowValData = [...showValidationData];
                tmpValData.forEach(element => {
                    if (element["validationID"] == executeData["validationID"])
                    {
                        element["status"] = "Processing";
                        element["lastRun"] = executeData["lastRun"];
                    }
                });
                tmpShowValData.forEach(element => {
                    if (element["validationID"] == executeData["validationID"])
                    {
                        element["status"] = "Processing";
                        element["lastRun"] = executeData["lastRun"];
                    }
                });
                tmpValData.sort((a, b) => new Date(a["lastRun"]).valueOf() - new Date(b["lastRun"]).valueOf()).reverse();
                tmpShowValData.sort((a, b) => new Date(a["lastRun"]).valueOf() - new Date(b["lastRun"]).valueOf()).reverse();
                setValidationData([...tmpValData]);
                setShowValidationData([...tmpShowValData]);
                history.push("/datavalidation");
                }
            });
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (validationData.length > 0)
            {
                updateIntervalValidations();
            }
          }, 15000);
        
          return () => {
            clearInterval(interval);
        };
    },[validationData]);

    return (
        <>
        <div className={globalClasses.textBar} style={{paddingBottom: "0"}}>
                    <Typography variant="h5" color="textPrimary" align="left">
                        {'Data validation'}
                    </Typography>
                    {/* <p className={classes.topBarItem}>KONE Material:</p>
                <ReactDropdown options={materials} value={defaultMaterial} placeholder="-"
            onChange={onDropdownSelect} className={clsx(classes.materialDropdown, classes.topBarItem)} /> */}
                    <div className={globalClasses.createTestButtonDiv}>
                        <Button variant="contained" className={clsx(globalClasses.blueButton)} onClick={handleNewValidation}>
                            <Add /> New data validation
                        </Button>
                    </div>
                </div>

                <div>
        {validtaionsUpdatedTime != null
                    ?
                    <Typography variant="body1" color="textSecondary" align="right" style={{fontSize: "0.75rem", marginLeft: "auto", marginRight: "7px", marginTop: "auto"}}>
                        {"Last refreshed: " + new Date(validtaionsUpdatedTime).toLocaleString()}
                    </Typography>
                    :
                    <></>
                    }
        </div>

                {
                    showEmpty == true
                    ?
                    <></>
                    :
                    <>
                <TableContainer component={Paper} className={clsx(classes.tableContainer)}>
        <InfiniteScroll
            pageStart={0}
            loadMore={validationData.length == 0 ? ()=>{} : loading}
            hasMore={(endReached && !validationsLoading) ? false : (true || false)}
            loader={ <CircularProgress key={0} />}
            useWindow={false}
            >
            <Table className={globalClasses.mainTable}>
                <TableHead>
                    <TableRow key={-2} className={globalClasses.titleRow}>
                            <TableCell key={"DVExecute"} className={globalClasses.titleCell} style={{width: "4%"}}>
                                {""}
                            </TableCell>
                        <TableCell key={"DataValidation"} className={globalClasses.titleCell}>
                            {""}
                        </TableCell>
                        <TableCell key={"Status"} className={globalClasses.titleCell}>
                            {""}
                        </TableCell>
                        <TableCell key={"Type"} className={globalClasses.titleCell}>
                            {""}
                        </TableCell>
                        <TableCell key={"Edit"} className={globalClasses.titleCell}>
                            {""}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {showValidationData.map((element, index) => {
                        return(
                        <TableRow key={element["name"] + "_" + index.toString()} className={globalClasses.titleRow}>
                        {/* <TableCell>
                            <Checkbox 
                                indeterminate={numSelected > 0 && numSelected < rowCount}
                                checked={rowCount > 0 && numSelected === rowCount}
                                onChange={onSelectAllClick}
                                />
                            </TableCell> */}
                        <TableCell className={classes.customTableCell}>
                                {element["status"] != "Processing"
                                ?
                                <Tooltip title={"Run Data validation"}>
                                    <IconButton className={clsx(globalClasses.customButton, globalClasses.blueColor)} onClick={(e)=>{
                                        HandleExecuteDV(element);
                                    }}>
                                    <PlayArrow />
                                    </IconButton>
                                </Tooltip>
                                :
                                <></>
                                }
                            </TableCell>
                            <TableCell className={classes.customTableCell}>
                        <ListItemText
                            style={{ margin: "0"}}
                            
                            disableTypography
                            primary={<Typography className={clsx({ //currentSelection.indexOf(index3) > -1,
                                [globalClasses.smallFont]: true
                            })}>{element["name"] + ", " + element["description"]}</Typography>}
                            secondary={<Typography style={{ fontSize: "0.75rem", color: "rgba(0, 0, 0, 0.55)"}}>{ConvertedTimeString(element["created"])}</Typography>}
                />
                        </TableCell>
                        <TableCell className={classes.customTableCell} align="center">
                                <div>
                            <Tooltip title={element["status"]}>
                                <span>
                                <CustomStatusIcon status={element["status"]}></CustomStatusIcon>
                                </span>
                            </Tooltip>
                                </div>
                        </TableCell>
                        <TableCell className={classes.customTableCell} align="center">
                            {/* <Typography className={globalClasses.smallFont}>{element["type"]}</Typography> */}
                            <ListItemText
                            style={{ margin: "0"}}
                            
                            disableTypography
                            primary={<Typography className={clsx({ //currentSelection.indexOf(index3) > -1,
                                [globalClasses.smallFont]: true
                            })}>{element["validationType"] != null && element["validationType"] == "Structure" ?  (element["pdmDescription"]) ? element["materials"][0] : element["validationType"] : element["validationType"]}</Typography>}
                            secondary={<Typography style={{ fontSize: "0.75rem", color: "rgba(0, 0, 0, 0.55)", whiteSpace: "pre-line"}}>{(element["pdmDescription"]) ? element["pdmDescription"] + "\n" : ""}{element["status"] == "Not run" ? " " : ConvertedTimeString(element["lastRun"])}</Typography>}
                />
                        </TableCell>
                        <TableCell className={classes.customTableCell} align="right">
                        {
                            ((element["status"] == "Processing" || element["status"] == "InProgress") && element["status"] != "Cancelled") 
                            ?
                            <IconButton title={"Cancel"} style={{marginRight: "8px"}} className={globalClasses.customButton} onClick={(e)=>{
                                // Cancel button functionality
                                CancelButton(element);
                                e.stopPropagation();
                            }}>
                                <CancelOutlined />
                            </IconButton>
                            : 
                            null 
                        }
                        {element["status"] == "Success"
                        ?
                        <IconButton className={globalClasses.customButton} onClick={(e)=>{
                            e.stopPropagation();
                            setSelectedDataValidation(element);
                            if (element["name"] != null)
                              handleDownload(instance, element["name"]);
                            }}>
                            <GetApp />
                        </IconButton>
                        :
                        null
                        }
                        <IconButton className={globalClasses.customButton} onClick={(e)=>{
                            setSelectedDataValidation(element);
                            setAnchorElDataVal(e.target);
                            e.stopPropagation();
                            }}>
                            <MoreHoriz />
                        </IconButton>
                        </TableCell>
                    </TableRow>
                    );
                    })}
                    {/* {batchesExcel} */}
                </TableBody>
                {
                    showFooter ?
                    <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                    colSpan={3}
                                    count={valLen}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                    
                                    />
                            </TableRow>
                        </TableFooter>
                        :
                        null
                    }
            </Table>
            </InfiniteScroll>
        </TableContainer>
        
            <Menu
                id="simple-menu"
                anchorEl={anchorElDataVal}
                getContentAnchorEl={null}
                keepMounted={false}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={Boolean(anchorElDataVal)}
                onClose={handleDataValClose}
            // PaperProps={{
            //     style: {
            //       transform: 'translateX(0) translateY(64px)',
            //     },
            //   }}
            >
                <MenuItem 
                disabled={selectedDataValidation == null ? false : selectedDataValidation["status"] == "Processing" ? true : false}
                onClick={() => {
                    props.setSelectedDataValidation(selectedDataValidation);
                    history.push("/datavalidation/edit")
                }}>Edit</MenuItem>
                <MenuItem disabled={selectedDataValidation == null ? false : selectedDataValidation["status"] == "Processing" ? true : false} onClick={handleValidationDeleteClick}>Delete</MenuItem>
            </Menu>
        </>}
        <Dialog
        open={openModal}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="alert-dialog-title">Validation Cancelled</DialogTitle>
        <DialogContent>
          <div style={{ whiteSpace: 'pre-line' }}>
            {modalMessage}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleUpdate} 
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  
     

    );
}
